import ApiConstants from "../utils/ApiConstants";
import Axios from "../utils/Axios";

export default class SignupService {
    static validateEmailAndContact(params) {
        return Axios.get(ApiConstants.URL.IS_USER_EXIST, { params: params });
    }

    static signup(payload) {
        return Axios.post(ApiConstants.URL.SIGN_UP, payload);
    }
}
