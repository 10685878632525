import Axios from "../utils/Axios";
import EnvConstants from "../utils/EnvConstants";

export default class SheetDBService {
    static postSignupData(payload) {
        return Axios.post(EnvConstants.SHEETDB_URL, JSON.stringify(payload), {
            withCredentials: false,
            headers: {
                'access-control-allow-origin': '*',
                'Authorization': `Bearer ${EnvConstants.SHEETDB_AUTH_TOKEN}`,
                'Content-Type': 'application/json',
            }
        });
    }
}
