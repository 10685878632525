import React, { useCallback, useEffect, useState } from 'react';
import DeskeraInfo from '../components/DeskeraInfo';
import UserInfo from '../components/UserInfo';
import CompanyInfo from '../components/CompanyInfo';
import SuccessMsg from '../components/SuccessMsg';
import SignupService from '../services/SignupService';
import SheetDBService from '../services/SheetDBService';
import { SIGNUP_STEP } from '../utils/Constants';
import { showToast, TOAST_TYPE } from '../components/common/Toast';

const SignupPage = () => {
  const [currentStep, setCurrentStep] = useState(SIGNUP_STEP.USER_INFO);
  const [showSpinner, setShowSpinner] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [userData, setUserData] = useState({
    name: '',
    emailId: '',
    contact: '',
    password: '',
    organizationName: '',
    role: '',
    companySize: ''
  });

  const formatDateTime = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();
    
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  const postDataToGoogleSheet = useCallback((formData) => {
    const payload = {
      data: [
        {
          'First Name': formData.firstName,
          'Last Name': formData.lastName,
          'Email Id': formData.emailId,
          Contact: formData.contact,
          Password: '',
          'Company Name': formData.organizationName,
          Role: formData.role,
          'Company Size': formData.companySize,
          'Date': formatDateTime(new Date())
        }
      ]
    };

    SheetDBService.postSignupData(payload).then(
      (response) => {},
      (error) => {}
    );
  }, []);

  const createNewAccount = useCallback(() => {
    setShowSpinner(true);

    let firstName = userData.name;
    let lastName = '';
    if (userData.name.indexOf(' ') !== -1) {
      const name = userData.name.split(' ');
      firstName = name[0];
      lastName = name[1];
    }

    const payload = {
      firstName: firstName,
      lastName: lastName,
      emailId: userData.emailId,
      contact: userData.contact,
      password: userData.password,
      organizationName: userData.organizationName.trim(),
      role: userData.role,
      companySize: userData.companySize,
      shouldSendEmail: true,
      isBookkeeper: false,
      isTeam: true
    };

    SignupService.signup(payload).then(
      (response) => {
        setShowSpinner(false);
        setDisableSubmit(false);

        gotoNextStep(SIGNUP_STEP.SUCCESS_MSG);
        postDataToGoogleSheet(payload);
      },
      (error) => {
        setShowSpinner(false);
        setDisableSubmit(false);
        showToast("Internal server error. Please try again later.", TOAST_TYPE.FAILURE);
      }
    );
  }, [userData, postDataToGoogleSheet]);

  useEffect(() => {
    if (userData?.step) {
      switch (userData.step) {
        case SIGNUP_STEP.USER_INFO:
          gotoNextStep(SIGNUP_STEP.COMPANY_INFO);
          break;
        case SIGNUP_STEP.COMPANY_INFO:
          setDisableSubmit(true);
          createNewAccount();
          break;
        default:
          break;
      }
    }
  }, [userData, createNewAccount]);

  const handleStepSubmit = (data) => {
    setUserData((prevData) => ({ ...prevData, ...data }));
  };

  const gotoNextStep = (nextStep) => {
    setCurrentStep(nextStep);
  };

  return (
    <div className="signup-page">
      <main className="signup-container">
        <div className="signup-content">
          <div className="left-column">
            <DeskeraInfo />
          </div>
          <div className="right-column">
            {currentStep === SIGNUP_STEP.USER_INFO && <UserInfo onSubmit={handleStepSubmit} />}
            {currentStep === SIGNUP_STEP.COMPANY_INFO && (
              <CompanyInfo showSpinner={showSpinner} disableSubmit={disableSubmit} onSubmit={handleStepSubmit} />
            )}
            {currentStep === SIGNUP_STEP.SUCCESS_MSG && <SuccessMsg onSubmit={handleStepSubmit} />}
          </div>
        </div>
      </main>
    </div>
  );
};

export default SignupPage;
