import { getLabel } from '../../utils/CommonUtils';

const Input = ({ id, type, label, className, value, required, invalid, errorMsg, onChange }) => {
  return (
    <>
      <label className={invalid && 'invalid-label'} htmlFor={id}>
        {getLabel(label, required)}
      </label>
      <input
        id={id}
        type={type}
        className={`${invalid && 'invalid-input'} ${className}`}
        value={value}
        required={required}
        onChange={(e) => {
          e.preventDefault();
          onChange(e.target.value);
        }}
        onBlur={(e) => {
          e.preventDefault();
          onChange(e.target.value.trim());
        }}
      />
      {invalid && errorMsg && <div className="error-msg">{errorMsg}</div>}
    </>
  );
};

export default Input;
