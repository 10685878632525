import React, { useEffect } from 'react';
import './index.css';
import SignupPage from './pages/SignupPage';
import TagManager from 'react-gtm-module';
import EnvConstants from './utils/EnvConstants';

function App() {
  useEffect(() => {
    // Initialize GTM
    const tagManagerArgs = {
      gtmId: EnvConstants.GTM_ID
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div className="App">
      <SignupPage />
    </div>
  );
}

export default App;
