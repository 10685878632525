import React, { useState } from 'react';
import 'react-international-phone/style.css';
import { PhoneInput } from 'react-international-phone';
import { REGEX, SIGNUP_STEP } from '../utils/Constants';
import EnvConstants from '../utils/EnvConstants';
import Input from './common/Input';
import { getLabel } from '../utils/CommonUtils';
import { PhoneNumberUtil } from 'google-libphonenumber';
import SignupService from '../services/SignupService';
import Spinner from './common/Spinner';
import visible from '../assets/visible.png';
import invisible from '../assets/invisible.png';

const UserInfo = ({ onSubmit }) => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  const [name, setName] = useState({
    id: 'name',
    type: 'text',
    label: 'Name',
    value: '',
    required: true,
    invalid: false
  });

  const [emailId, setEmailId] = useState({
    id: 'emailId',
    type: 'email',
    label: 'Work Email',
    value: '',
    required: true,
    pattern: REGEX.EMAIL,
    invalid: false
  });

  const [contact, setContact] = useState({
    id: 'contact',
    type: 'text',
    label: 'Phone Number',
    value: '',
    meta: null,
    required: true,
    invalid: false
  });

  const [password, setPassword] = useState({
    id: 'password',
    type: 'password',
    label: 'Password',
    value: '',
    required: true,
    pattern: REGEX.PASSWORD,
    invalid: false
  });

  const [showSpinner, setShowSpinner] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.invalid || emailId.invalid || contact.invalid || password.invalid) {
      return;
    }

    setDisableSubmit(true);
    if (!emailId.invalid) {
      const domain = emailId.value.substring(emailId.value.indexOf('@') + 1);
      if (EnvConstants.DENIED_MAIL_DOMAIL.includes(domain)) {
        setEmailId((prevState) => {
          return {
            ...prevState,
            invalid: true,
            errorMsg: 'This email domain is not allowed. Please use a different one.'
          };
        });
        setDisableSubmit(false);
        return;
      }
    }

    if (!contact.invalid) {
      const value = contact.meta.inputValue?.replace(`+${contact.meta.country.dialCode}`, '');
      if (value?.trim()?.length === 0) {
        setContact((prevState) => {
          return {
            ...prevState,
            invalid: true,
            errorMsg: 'This phone number is required field.'
          };
        });
        setDisableSubmit(false);
        return;
      }
    }

    if (!password.invalid) {
      let passwordText = password.value;
      if (passwordText.match(/^( )+/)) {
        passwordText = passwordText.replace(/^\s+/g, '');
      }

      passwordText = passwordText.replace(/ /g, '');
      if (passwordText.length < 8) {
        setPassword((prevState) => {
          return {
            ...prevState,
            invalid: true,
            errorMsg: 'Password cannot be less than 8 characters excluding spaces.'
          };
        });
        setDisableSubmit(false);
        return;
      }

      if (passwordText.length > 73) {
        setPassword((prevState) => {
          return {
            ...prevState,
            invalid: true,
            errorMsg: 'Password cannot be greater than 73 characters including spaces.'
          };
        });
        setDisableSubmit(false);
        return;
      }
    }

    if (!emailId.invalid && !contact.invalid) {
      const params = {
        contact: contact.value,
        emailId: emailId.value,
        action: 'checkExistingUserDetails'
      };

      setShowSpinner(true);
      SignupService.validateEmailAndContact(params).then(
        (response) => {
          setShowSpinner(false);

          if (response.emailExists) {
            setEmailId((prevState) => {
              return {
                ...prevState,
                invalid: true,
                errorMsg: 'This email is already registered. Please use a different one.'
              };
            });
          }

          if (response.contactExists) {
            setContact((prevState) => {
              return {
                ...prevState,
                invalid: true,
                errorMsg: 'This phone number is already registered. Please use a different one.'
              };
            });
          }

          if (response.emailExists || response.contactExists) {
            setDisableSubmit(false);
            return;
          }

          onSubmit({
            step: SIGNUP_STEP.USER_INFO,
            name: name.value,
            emailId: emailId.value,
            contact: contact.value,
            password: password.value
          });
        },
        (error) => {
          setDisableSubmit(false);
          setShowSpinner(false);
        }
      );
    }
  };

  return (
    <div className="email-step">
      <div className="step-info">
        <h1>Create New Account</h1>
        <span className="text-uppercase">Already registered?</span>
        <a className="text-uppercase text-login" href={EnvConstants.LOGIN_URL}>
          Login
        </a>
      </div>
      <form onSubmit={handleSubmit} className="signup-form">
        <div className="form-group">
          <Input
            id={name.id}
            type={name.type}
            label={name.label}
            value={name.value}
            required={name.required}
            invalid={name.invalid}
            errorMsg={name.errorMsg}
            onChange={(value) => {
              setName((prevState) => {
                return {
                  ...prevState,
                  errorMsg: null,
                  value: value
                };
              });
            }}
          />
        </div>
        <div className="form-group">
          <Input
            id={emailId.id}
            type={emailId.type}
            label={emailId.label}
            value={emailId.value}
            required={emailId.required}
            invalid={emailId.invalid}
            errorMsg={emailId.errorMsg}
            onChange={(value) => {
              setEmailId((prevState) => {
                return {
                  ...prevState,
                  invalid: !emailId.pattern.test(value),
                  errorMsg: null,
                  value: value
                };
              });
            }}
          />
        </div>
        <div className="form-group">
          <label className={contact.invalid && 'invalid-label'} htmlFor={contact.id}>
            {getLabel(contact.label, contact.required)}
          </label>
          <PhoneInput
            className={contact.invalid ? 'invalid-contact-container' : 'contact-container'}
            inputClassName={contact.invalid ? 'invalid-contact-input' : 'contact-input'}
            defaultCountry="us"
            preferredCountries={['us', 'sg', 'in', 'my', 'id']}
            value={contact.value}
            onChange={(value, meta) => {
              let invalid = false;
              const inputValue = meta.inputValue?.replace(`+${meta.country.dialCode}`, '');
              if (inputValue?.trim()?.length > 0) {
                try {
                  invalid = !phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(value));
                } catch (error) {
                  invalid = true;
                }
              }

              setContact((prevState) => {
                return {
                  ...prevState,
                  invalid: invalid,
                  errorMsg: null,
                  value: value,
                  meta: meta
                };
              });
            }}
          />
          {contact.invalid && contact.errorMsg && <div className="error-msg">{contact.errorMsg}</div>}
        </div>
        <div className="form-group password-form-group">
          <Input
            id={password.id}
            type={password.type}
            label={password.label}
            className={"password-input"}
            value={password.value}
            required={password.required}
            invalid={password.invalid}
            errorMsg={password.errorMsg}
            onChange={(value) => {
              setPassword((prevState) => {
                const invalid = !password.pattern.test(value);
                let errorMsg = null;
                if (invalid) {
                  if (value.length < 8 || value.length > 73) {
                    errorMsg = 'Password must be between 8 and 73 characters long.';
                  } else {
                    errorMsg = 'Password cannot start or end with spaces.';
                  }
                }

                return {
                  ...prevState,
                  invalid: invalid,
                  errorMsg: errorMsg,
                  value: value
                };
              });
            }}
          />
          <div className="visibility-icon">
            <img
              src={password.type === 'password' ? invisible : visible}
              alt="visible"
              onClick={() => {
                setPassword((prevState) => {
                  return {
                    ...prevState,
                    type: prevState.type === 'password' ? 'text' : 'password'
                  };
                });
              }}
            />
          </div>
        </div>
        <div>
          <button type="submit" className="btn-primary" disabled={disableSubmit}>
            Continue
            {showSpinner && <Spinner />}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserInfo;
